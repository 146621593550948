import IRole from '@src/interfaces/IRole';
import {ColumnDef} from '@tanstack/react-table';
import {ActionsCell} from '../ActionsCell/ActionsCell';
import {ModifiedCell} from '@src/pages/RolesPage/components/ModifiedCell/ModifiedCell';
import {CreatedCell} from '@src/pages/RolesPage/components/CreatedCell/CreatedCell';

export const buildRolesColumns: Array<ColumnDef<IRole>> = [
	{
		accessorKey: 'name',
		header: 'Название'
	},
	{
		accessorKey: 'company',
		header: 'Компания',
		accessorFn: originalRow => originalRow.company?.name
	},
	{
		accessorKey: 'createdAt',
		header: 'Создано',
		cell: CreatedCell
	},
	{
		accessorKey: 'modifiedAt',
		header: 'Изменено',
		cell: ModifiedCell,
		size: 100
	},
	{
		id: 'actions',
		cell: ActionsCell,
		size: 20
	}
];
