import {createAction} from '@reduxjs/toolkit';
import * as types from '@src/store/modules/entities/roles/constants';
import {makeRoleCopyRequest} from '@src/api/backend/roles/copy';
import {IAddRoleResponse} from '@src/api/backend/roles';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@tehzor/ui-components';
import {createApiAction} from '@src/store/middlewares/api';

const copyRoleRequest = createAction(types.COPY_ROLE_REQUEST);
const copyRoleSuccess = createAction<IAddRoleResponse>(types.COPY_ROLE_SUCCESS);
const copyRoleFailure = createAction(types.COPY_ROLE_FAILURE, (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast({title: 'Ошибка при копировании роли'});
	}
	return {
		payload: {...error}
	};
});

export const copyRole = (roleId: string) =>
	createApiAction(
		copyRoleRequest,
		copyRoleSuccess,
		copyRoleFailure,
		() => makeRoleCopyRequest(roleId)
	);
