import {memo, MouseEvent, useCallback} from 'react';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {IconButton} from '@tehzor/ui-components';
import {deleteRole} from '@src/store/modules/entities/roles/actions/delete';
import './DeleteRoleButton.less';

interface IDeleteRoleButtonProps {
	id: string;
	name: string;
}

const deleteIcon = <i className="tz-delete" />;

export const DeleteRoleButton = memo(({id, name}: IDeleteRoleButtonProps) => {
	const dispatch = useAppDispatch();

	const [dialog, getDeleteConfirmation, changeDialogData] = useConfirmDialog(
		`Вы действительно хотите удалить "${name}"?`,
		undefined,
		{acceptBtnProps: {type: 'accent-red'}}
	);

	useUpdateEffect(() => {
		changeDialogData({title: `Вы действительно хотите удалить "${name}"?`})
	}, [name]);

	const handleRoleDelete = useCallback(
		async (e: MouseEvent<HTMLButtonElement>) => {
			e.stopPropagation();
			if (await getDeleteConfirmation()) {
				await dispatch(deleteRole(id));
			}
		},
		[id]
	);

	return (
		<div className="delete-role">
			<IconButton
				className="delete-role__button"
				onClick={handleRoleDelete}
				type="transparent"
			>
				{deleteIcon}
			</IconButton>
			{dialog}
		</div>
	);
});
