import BaseSocketConnector from '@tehzor/tools/api/BaseSocketConnector';
import getTimezone from '@tehzor/tools/utils/getTimezone';

const singleton = Symbol();
const singletonEnforcer = Symbol();

/**
 * Singleton класс для взаимодействия с backend'ом
 */
class SocketConnector extends BaseSocketConnector {
	/**
	 * Возвращает единственный экземпляр класса SocketConnector
	 *
	 * @returns {SocketConnector}
	 */
	static get instance() {
		if (!this[singleton]) {
			this[singleton] = new SocketConnector(singletonEnforcer);
		}
		return this[singleton];
	}

	constructor(enforcer) {
		super();
		if (enforcer !== singletonEnforcer) {
			throw 'Cannot construct \'SocketConnector\' class';
		}
	}

	/**
	 * Получает список компаний
	 *
	 * @return {Promise}
	 */
	getCompanies = () => this._sendAuthorizedRequest('getCompanies');

	/**
	 * Добавляет новую компанию
	 *
	 * @param {ISavingCompany} fields поля компании
	 * @returns {Promise}
	 */
	addCompany = fields => this._sendAuthorizedRequest('addCompany', fields);

	/**
	 * Изменяет компанию
	 *
	 * @param {string} companyId id компании
	 * @param {ISavingCompany} fields поля компании
	 * @returns {Promise}
	 */
	editCompany = (companyId, fields) => {
		const params = {
			...fields,
			companyId
		};
		return this._sendAuthorizedRequest('editCompany', params);
	};

	/**
	 * Удаляет компанию
	 *
	 * @param {string} companyId id компании
	 * @returns {Promise}
	 */
	deleteCompany = companyId => {
		const params = {companyId};
		return this._sendAuthorizedRequest('deleteCompany', params);
	};

	/**
	 * Получает список пользователей
	 *
	 * @returns {Promise}
	 */
	getUsers = () => this._sendAuthorizedRequest('getUsers');

	/**
	 * Добавляет нового пользователя
	 *
	 * @param {ISavingUser} fields данные пользователя
	 * @return {Promise}
	 */
	addUser = fields => this._sendAuthorizedRequest('addUser', fields);

	/**
	 * Изменяет данные о пользователе
	 *
	 * @param {string} id id пользователя
	 * @param {ISavingUser} fields данные пользователя
	 * @return {Promise}
	 */
	editUser = (id, fields) => {
		const params = {
			...fields,
			id
		};
		return this._sendAuthorizedRequest('editUser', params);
	};

	/**
	 * Удаляет пользователя
	 *
	 * @param {string} id id пользователя
	 * @return {Promise} action
	 */
	deleteUser = id => {
		const params = {
			id
		};
		return this._sendAuthorizedRequest('deleteUser', params);
	};

	/**
	 * Получает список СП
	 *
	 * @return {Promise}
	 */
	getRegulatoryStandards = () => this._sendAuthorizedRequest('getRegulatoryStandards');

	/**
	 * Обновляет список СП
	 *
	 * @return {Promise}
	 */
	updateRegulatoryStandards = () => this._sendAuthorizedRequest('updateRegulatoryStandards');

	/**
	 * Востановление помеченного на удаление объекта.
	 *
	 * @param {String} objectId id объекта
	 * @returns {Promise}
	 */
	restoreObject = objectId => {
		const params = {
			objectId
		};
		return this._sendAuthorizedRequest('restoreObject', params);
	};

	/**
	 * Получаем список всех заархивированных объектов.
	 *
	 * @returns {Promise}
	 */
	getArchivedObjects = () => this._sendAuthorizedRequest('getArchivedObjects');

	/**
	 * Архивирует объект.
	 *
	 * @param {String} objectId id объекта
	 * @returns {Promise}
	 */
	addArchiveObject = objectId => {
		const params = {
			objectId
		};
		return this._sendAuthorizedRequest('addArchiveObject', params);
	};

	/**
	 * Востановление заархивированного объекта.
	 *
	 * @param {Array} archiveObjects id объектов
	 * @returns {Promise}
	 */
	restoreArchiveObject = archiveObjects => {
		const params = {
			archiveObjects
		};
		return this._sendAuthorizedRequest('restoreArchiveObject', params);
	};

	/**
	 * Удаляет заархивированные объекты.
	 *
	 * @param {String} archiveObjectId id объекта
	 * @returns {Promise}
	 */
	deleteArchiveObject = archiveObjectId => {
		const params = {
			archiveObjectId
		};
		return this._sendAuthorizedRequest('deleteArchiveObject', params);
	};

	/**
	 * Получает список шаблонов экспорта
	 *
	 * @returns {Promise}
	 */
	getExportTemplates = () => this._sendAuthorizedRequest('getExportTemplates');

	/**
	 * Добавляет шаблон экспорта
	 *
	 * @param {String} name название шаблона экспорта
	 * @param {String} companyId id комании
	 * @param {String} destination тип шаблона экспорта
	 * @param {String} outputFileType тип экспортируемого файла
	 * @param {String} prefix первичный префик в имени экспортируемого файла
	 * @param {Object} file файл
	 * @returns {Promise}
	 */
	addExportTemplate = (name, companyId, destination, outputFileType, prefix, file) => {
		const params = {
			name,
			destination,
			outputFileType,
			prefix,
			file
		};
		if (companyId) params.companyId = companyId;

		return this._sendAuthorizedRequest('addExportTemplate', params);
	};

	/**
	 * Изменяет шаблон экспорта
	 *
	 * @param {String} id id шаблона экспорта
	 * @param {String} name название шаблона экспорта
	 * @param {String} companyId id комании
	 * @param {String} destination тип шаблона экспорта
	 * @param {String} outputFileType тип экспортируемого файла
	 * @param {String} prefix первичный префик в имени экспортируемого файла
	 * @param {Object} file файл
	 * @returns {Promise}
	 */
	editExportTemplate = (id, name, companyId, destination, outputFileType, prefix, file) => {
		const params = {id};
		if (name) params.name = name;
		if (companyId !== undefined) params.companyId = companyId;
		if (destination) params.destination = destination;
		if (outputFileType) params.outputFileType = outputFileType;
		if (prefix) params.prefix = prefix;
		if (file) params.file = file;

		return this._sendAuthorizedRequest('editExportTemplate', params);
	};

	/**
	 * Удаляет шаблон экспорта
	 *
	 * @param {String} id id шаблона экспорта
	 * @returns {Promise}
	 */
	deleteExportTemplate = id => {
		const params = {id};
		return this._sendAuthorizedRequest('deleteExportTemplate', params);
	};

	/**
	 * Скачивает файл шаблона экспорта
	 *
	 * @param {string} id id шаблона экспорта
	 * @returns {Promise}
	 */
	downloadExportTemplateFile = id => {
		const params = {id};
		return this._getAuthorizedFileRequest('downloadExportTemplateFile', params);
	};

	getRoles = () => this._sendAuthorizedRequest('getRoles');

	addRole = fields => this._sendAuthorizedRequest('addRole', fields);

	copyRole = id => {
		const params = {
			id
		};
		return this._sendAuthorizedRequest('copyRole', params);
	};

	editRole = (id, fields) => {
		const params = {
			id,
			...fields
		};
		return this._sendAuthorizedRequest('editRole', params);
	};

	deleteRole = id => {
		const params = {
			id
		};
		return this._sendAuthorizedRequest('deleteRole', params);
	};

	getRolePermissions = () => this._sendAuthorizedRequest('getRolePermissions');

	/**
	 * Получает список записей лога
	 *
	 * @param {Object} filter фильтры
	 * @param {Object} sort параметры сортировки
	 * @param {Number} offset индекс начального элемента
	 * @param {Number} limit количество элементов
	 * @returns {Promise}
	 */
	getLogRecords = (filter, sort, offset, limit) => {
		const params = {
			timezone: getTimezone()
		};
		if (filter) {
			params.filter = filter;
		}
		if (sort) {
			params.sort = sort;
		}
		if (offset) {
			params.offset = offset;
		}
		if (limit) {
			params.limit = limit;
		}
		return this._sendAuthorizedRequest('getLogRecords', params);
	};

	/**
	 * Получает список типов записей лога
	 *
	 * @return {Promise}
	 */
	getLogRecordTypes = () => this._sendAuthorizedRequest('getLogRecordTypes');

	/**
	 * Возвращает список настраиваемых полей
	 *
	 * @return {Promise}
	 */
	getCustomizableFields = () => this._sendAuthorizedRequest('getCustomizableFields');

	/**
	 * Генерирует квартиры из планов и размеченных секторов
	 *
	 * @param {String} objectId id объекта
	 * @returns {Promise}
	 */
	generateApartments = objectId => this._sendAuthorizedRequest('generateApartments', {objectId});
}

export default SocketConnector;
