import {createAction} from '@reduxjs/toolkit';
import * as types from '@src/store/modules/entities/roles/constants';
import {IAddRoleResponse, makeRoleAddRequest} from '@src/api/backend/roles';
import IError from '@tehzor/tools/interfaces/IError';
import ISavingRole from '@src/interfaces/ISavingRole';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@tehzor/ui-components';

const addRoleRequest = createAction(types.ADD_ROLE_REQUEST);
const addRoleSuccess = createAction<IAddRoleResponse>(types.ADD_ROLE_SUCCESS);
const addRoleFailure = createAction(types.ADD_ROLE_FAILURE, (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast({title: 'Ошибка при добавлении роли'});
	}
	return {
		payload: {...error}
	};
});

export const addRole = (role: ISavingRole) =>
	createApiAction<IAddRoleResponse>(
		addRoleRequest,
		addRoleSuccess,
		addRoleFailure, () =>
		makeRoleAddRequest(role)
	);
