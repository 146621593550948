import {memo, MouseEvent, useCallback} from 'react';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {IconButton} from '@tehzor/ui-components';
import {copyRole} from '@src/store/modules/entities/roles/actions/copy';
import './CopyRoleButton.less';

interface ICopyRoleButtonProps {
	id: string;
}

const copyIcon = <i className="tz-copy" />;

export const CopyRoleButton = memo(({id}: ICopyRoleButtonProps) => {
	const dispatch = useAppDispatch();

	const handleRoleCopy = useCallback(
		async (e: MouseEvent<HTMLButtonElement>) => {
			e.stopPropagation();
			await dispatch(copyRole(id));
		},
		[id]
	);

	return (
		<div className="copy-role">
			<IconButton
				className="copy-role__button"
				onClick={handleRoleCopy}
				type="transparent"
			>
				{copyIcon}
			</IconButton>
		</div>
	);
});
