import {memo} from 'react';
import {CellContext} from '@tanstack/react-table';
import {CopyRoleButton} from '../CopyRoleButton/CopyRoleButton';
import {DeleteRoleButton} from '../DeleteRoleButton/DeleteRoleButton';
import IRole from '@src/interfaces/IRole';
import './ActionsCell.less';

export const ActionsCell = memo(({row}: CellContext<IRole, IRole>) => (
	<div className="actions-cell">
		<CopyRoleButton id={row.original.id} />
		<DeleteRoleButton
			id={row.original.id}
			name={row.original.name}
		/>
	</div>
));
